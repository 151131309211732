import { template as template_b11157218bcd4493a93c609399ec41ab } from "@ember/template-compiler";
const FKText = template_b11157218bcd4493a93c609399ec41ab(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
