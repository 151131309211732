import { template as template_d0730e4262084bc0b5a26a6d1ea0a46c } from "@ember/template-compiler";
const FKLabel = template_d0730e4262084bc0b5a26a6d1ea0a46c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
